<template lang="pug">
  div
    el-alert(
      title="Bu bölümü görüntüleme yetkiniz yok"
      v-if="!can('users.list')"
      :closable="false"
      type="error"
      show-icon
      center
    )
    div(v-if="can('users.list')")
      el-card(body-style="padding: 0", shadow="hover")
        div(slot="header")
          el-form.pt-10(:inline="true", :model="filters")
            el-form-item(label="")
              el-button(
                size="mini"
                type="primary"
                @click="createUser"
                icon="el-icon-plus"
                :disabled="!can('users.create')"
              ) Oluştur
            el-form-item(label="Durumu")
              el-select(
                size="mini"
                v-model="filters.active"
                placeholder="Durumu seçiniz"
                @change="fetchUsers"
              )
                el-option(label="Tümü", :value="null")
                el-option(label="Sadece aktif", :value="1")
                el-option(label="Sadece pasif", :value="0")
            el-form-item.pull-right.no-margin-right(label="")
              el-input(placeholder="Ara...", v-model="search", size="mini")
                i.el-input__icon.el-icon-search(slot="suffix")
        el-table(
          :data="filteredUsers"
          @row-dblclick="editUser"
          size="mini"
          :stripe="true"
          v-loading="fetching"
          :height="$store.state.windowHeight-155"
        )
          el-table-column(type="index", width="35px", align="right", class-name="text-muted")
          el-table-column(prop="active", label="Aktif", align="center", width="80px", :sortable="true")
            template(slot-scope="props")
              el-switch(
                v-if="props.hasOwnProperty('row')"
                :value="props.row.active"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="activateUser(props.row, $event)"
              )
          el-table-column( prop="name", label="Ad, soyad", :sortable="true")
          el-table-column(prop="username", label="Kullanıcı adı", :sortable="true")
          el-table-column(prop="email", label="Email", :sortable="true")
      el-dialog(
        :visible.sync="modal"
        :title="user.name"
        v-if="modal"
        top="15px"
        width="80%"
        :center="true"
      )
        el-alert(
          title="Kullanıcı bilgilerini düzenleme yetkiniz yok"
          v-if="!can('users.edit')"
          :closable="false"
          type="warning"
        )
        el-form.p-20(ref="form", :model="user", label-width="170px")
          el-row(:gutter="20")
            el-col(:lg="10")
              el-form-item(label="Kullanıcı adı")
                el-input(v-model="user.name", :disabled="!can('users.edit')")
              el-form-item(label="Login")
                el-input(v-model="user.username", :disabled="!can('users.edit')")
              el-form-item(label="Parola")
                el-input(v-model="user.password", :disabled="!can('users.edit')")
              el-form-item(label="E-mail adresi")
                el-input(v-model="user.email", :disabled="!can('users.edit')")
              el-form-item(label="Aktif")
                el-switch(
                  v-model="user.active"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :disabled="!can('users.edit')"
                )
              el-form-item(label="Şubeler")
                el-select(
                  v-model="user.branches"
                  filterable
                  clearable
                  multiple
                  style="width: 100%"
                )
                  el-option(
                    v-for="s in branches"
                    :key="s.id"
                    :label="s.name"
                    :value="s.id"
                  )
              el-form-item(label="Depolar")
                el-select(
                  v-model="user.warehouses"
                  filterable
                  clearable
                  multiple
                  style="width: 100%"
                )
                  el-option(
                    v-for="s in warehouses"
                    :key="s.id"
                    :label="s.name"
                    :value="s.id"
                  )
            el-col(:lg="14" v-if="can('users.roles.list') || can('users.permissions.list')")
              el-form-item(label="Yetkiler")
                el-tree.pt-10(
                  :data="permissionsTree"
                  ref="permissionsTree"
                  node-key="name"
                  :check-on-click-node="true"
                  :render-after-expand="false"
                  :default-expanded-keys="[]"
                  :default-checked-keys="user.permissions"
                  :props="{ children: 'permissions' }"
                  @check-change="setUserPermissions"
                  :highlight-current="false"
                  :default-expand-all="false"
                  :show-checkbox="true"
                  :disabled="!can('users.permissions.edit')"
                )
        span.dialog-footer(slot="footer")
          el-button(
            type="primary"
            @click="saveUser"
            :disabled="!saveable || !can('users.edit')"
            :loading="saving"
          ) Kaydet
          el-button(@click="modal = false") Kapat
</template>
<script>
import Role from '@/models/Role'
import User from '@/models/User'
import Company from '@/models/Company'
import PermissionGroup from '@/models/PermissionGroup'

export default {
  name: 'Users',
  data() {
    return {
      user: null,
      roles: [],
      users: [],
      selectedUser: new User({
        name: '',
        roles: ['admin'],
        permissions: [],
        branches: [],
        warehouses: [],
      }),
      modal: false,
      fetching: false,
      fetchingExcel: false,
      saving: false,
      search: '',
      permissionGroups: [],
      branches: [],
      warehouses: [],
      filters: {
        active: 1,
        company_id: null,
        branch_id: null,
        roles: [
          'admin'
        ]
      }
    }
  },
  async mounted() {
    if(this.can('users.list')) {
      await this.fetchUsers()
      this.roles = await Role.get()
      this.permissionGroups = await PermissionGroup.include('permissions').get()
      this.branches = await Company.where('type', 'branch').get()
      this.warehouses = await Company.where('type', 'warehouse').get()
    }
  },
  methods: {
    async fetchUsers(excel) {
      try {
        if (excel === true) {
          this.fetching = false
          this.fetchingExcel = true
        } else {
          this.fetching = true
          this.fetchingExcel = false
        }
        const users = await User
          .whereIn('roles', this.filters.roles)
          .where('company_id', this.filters.company_id)
          .where('active', this.filters.active)
          .include('branches', 'warehouses')
          .params({ excel })
          .get()
        if (excel === true) {
          window.location.href = '/users.xlsx'
        } else {
          this.users = users
        }
        this.fetchingExcel = false
        this.fetching = false
      } catch (e) {
        console.log(e)
        //this.$message.error(response.data.message)
        this.fetching = false
      }
    },
    createUser() {
      this.user = new User({
        name: '',
        username: '',
        email: '',
        active: true,
        roles: ['admin'],
        permissions: [],
        branches: [],
        warehouses: []
      })
      this.modal = true
    },
    async editUser(row) {
      try {
        this.user = await User.include(['branches']).find(row.id)
        this.modal = true
      } catch ({ response }) {
        this.$message.error(response.data.message)
      }
    },
    async saveUser() {
      try {
        this.saving = true
        await this.user.save()
        this.saving = false
        this.modal = false
        await this.fetchUsers()
      } catch (e) {
        this.saving = false
        console.log(e)
      }
    },
    setUserPermissions(company, checked) {
      this.user.permissions = this.$refs.permissionsTree.getCheckedNodes().filter((n) => n.hasOwnProperty('name')).map((n) => n.name)
    },
    async activateUser(user, status) {
      try {
        this.$Progress.start()
        user.active = status
        await window.axios.post(`users/activate/${user.id}`)
        this.$message.success(status === true ? 'Kullanıcı aktif' : 'Kullanıcı pasif')
        this.$Progress.finish()
      } catch ({ response }) {
        this.$message.error(response.data.message)
        this.saving = false
        this.$Progress.finish()
      }
    }
  },
  computed: {
    saveable() {
      return (
        this.user.name.length &&
        this.user.roles.length &&
        this.user.username.length &&
        this.user.email.length &&
        this.user.branches.length > 0
      )
    },
    filteredUsers() {
      return this.users.filter((u) => u.name.toLowerCase().includes(this.search.toLowerCase()))
    },
    permissionsTree() {
      return [{
        label: 'Tüm yetkiler',
        id: 9999,
        permissions: this.permissionGroups
      }]
    }
  }
}
</script>
